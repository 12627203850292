/**
 * Enum representing the types of legal text that can be shown to the user.
 */

export enum LegalText {
  /**
   * Legal notice
   */
  LEGALNOTICE = 'LEGALNOTICE',

  /**
   * Privacy policy
   */
  PRIVACY = 'PRIVACY',

  /**
   * Cookies policy
   */
  COOKIES = 'COOKIES',

  /**
   * Terms and conditions
   */
  TERMS = 'TERMS'
}
